var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Intersect',{attrs:{"threshold":[0]},on:{"~enter":function($event){return (() => _vm.setViewportStatus(true)).apply(null, arguments)}}},[_c('div',{attrs:{"id":`img_${_vm.imageUuid}`}},[_c('Intersect',{attrs:{"rootMargin":`0px 0px 100% 0px`,"threshold":[0]},on:{"~enter":function($event){return _vm.doLazyTrigger.apply(null, arguments)}}},[_c('div',{staticClass:"bleach-image__lazy-target",attrs:{"id":`img_lazy_target_${_vm.imageUuid}`}})]),(!_vm.lazyOverride)?[_c('picture',{attrs:{"itemprop":"image","itemscope":"","itemtype":"http://schema.org/ImageObject","id":_vm.imageUuid}},[_vm._l((_vm.validatedSizes),function(transformations,breakpoint){return _c('source',{key:breakpoint,attrs:{"media":`(max-width: ${_vm.styles['BREAKPOINT_' + breakpoint]}px)`,"srcset":_vm.generateAssetUrls(transformations)}})}),(!_vm.isVideoPlaying)?_c('img',{class:{
            'bleach-image--loaded': _vm.isLoaded,
            'bleach-image--immediate': _vm.immediate,
            'bleach-image--contain': _vm.contain,
            'bleach-image--fixed': _vm.fixed,
            'bleach-image--autowidth': _vm.autoWidth
          },attrs:{"itemprop":"contentUrl url","srcset":_vm.generateAssetUrls(_vm.defaultSize),"src":_vm.generateAssetUrls(_vm.defaultSize).split(' ')[0],"alt":_vm.alt,"loading":_vm.immediate || _vm.isInViewport ? 'eager' : 'lazy'},on:{"load":_vm.handleMediaLoaded}}):_vm._e()],2),(_vm.isVideo && _vm.isVideoInit)?_c('video',{key:_vm.currentActiveBreakpoint
            ? _vm.currentActiveBreakpoint.breakpoint
            : 'default',ref:"videoElement",class:{
          'bleach-image--contain': _vm.contain,
          'bleach-image--fixed': _vm.fixed,
          'bleach-image--autowidth': _vm.autoWidth
        },attrs:{"muted":"","loop":"","playsinline":"","autoplay":""},domProps:{"muted":true},on:{"playing":_vm.handleVideoPlaying,"pause":_vm.handleVideoPlaying}},_vm._l((['mp4']),function(extension){return _c('source',{key:extension,attrs:{"type":`video/${extension}`,"src":_vm.generateAssetUrls(
              _vm.getCurrentActiveTransformations,
              true,
              extension
            )}})}),0):_vm._e()]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }